import { useEffect, useState } from 'react';

export const VetfluxSwitchButton = ({
  checked = false,
  onSwitchChange,
  readOnly = false,
  interactive = true,
}: {
  checked?: boolean;
  readOnly?: boolean;
  onSwitchChange: (isPublish: boolean) => void;
  interactive?: boolean;
}) => {
  const [enabled, setEnabled] = useState(false);
  const handleToggleClickEvent = (event) => {
    onSwitchChange(!enabled);
    if (interactive) {
      setEnabled(!enabled);
    }
  };
  useEffect(() => {
    if (checked !== undefined) {
      setEnabled(checked);
    }
  }, [checked]);
  return (
    <div className='flex flex-col'>
      <label className='inline-flex relative items-center mx-2 cursor-pointer'>
        <input
          type='checkbox'
          className='sr-only peer'
          checked={enabled}
          readOnly
        />
        <button
          disabled={readOnly}
          onClick={handleToggleClickEvent}
          className={`w-9 h-5 bg-gray-200 rounded-full peer peer-focus:ring-primary  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-primary ${
            readOnly ? 'cursor-not-allowed' : ''
          }`}
        />
      </label>
    </div>
  );
};
