import React, {
  MouseEventHandler,
  ReactComponentElement,
  ReactNode,
} from 'react';

type SignupButtonProps = {
  handleClick: MouseEventHandler;
};

export const VetfluxRoundButton = ({
  className,
  onClick,
  children,
  disabled,
}: {
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children?: ReactNode;
  disabled?: boolean;
}) => {
  return (
    <div>
      <button
        onClick={onClick}
        disabled={disabled}
        className={
          'flex items-center justify-center transition bg-white rounded-full shadow-md hover:bg-warmGray-50 text-primary hover:cursor-pointer ' +
          className
        }
      >
        {children}
      </button>
    </div>
  );
};
