import { useContext } from 'react';

import { AuthContext } from './authStore';

// 사용하고 싶은 곳에서 객체형태로 받아서 쓰기

const useAuthContext = () => {
  return useContext(AuthContext);
};

export { useAuthContext };
